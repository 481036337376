<script>

export default {
    data() {
       return {
        //    sugesstionArr: [],
        //    userSearchKey:'',
       } 
    },
    methods:{
        acceptTnc()
        {
            let getUserUpdatedDetails = JSON.stringify({ 
                                    isTncAgreed: 1,
                                    });
            this.axios.post('/updateUserdetails', getUserUpdatedDetails)
			.then((response)=>{
			// console.log(response.data.cuisineDetails);
			let status = response.status;
			if(status == 200 && response.data.status == 200)
			{
                location.reload();
            }
             }).catch((error) => {
			console.log(error.response.data.message);
			});
        },
    }
}
</script>

<template>
    <div  class="container-fuild m-0 p-0">
        <b-modal
                  id="modal-termsCondition"
                  size="md"
                  hide-footer
                  hide-header
                  no-close-on-backdrop
                >
            <div class="section-services container">
                <h2 class="setionTitle align-center">Terms &amp; Conditions</h2>
                <p class="desc">This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage of <a href="http://cloudkitch.co.in/" target="_blank">www.cloudkitch.co.in</a> website and CLOUDKITCH application for mobile and handheld devices.</p>
                    <h2 class="policyHead">Terms Of Use</h2>
                    <ul>
                        <li><p class="desc">These terms of use (the "&nbsp;Terms of Use") govern your use of our website www.cloudkitch.co.in (the "&nbsp;Website") and our "CLOUDKITCH" application for mobile and handheld devices (the "&nbsp;App"). The Website and the App are jointly referred to as the "&nbsp;Platform". Please read these Terms of Use carefully before you use the services. If you do not agree to these Terms of Use, you may not use the services on the Platform, and we request you to uninstall the App. By installing, downloading or even merely using the Platform, you shall be contracting with CLOUDKITCH and you signify...<a href="/policies/terms-and-condition" target="_blank" class="customAnch">Read more</a></p></li>
                    </ul>
            </div>
                <a href="#" @click="acceptTnc()" class="btn_1 full-width mb_5" style="color:#000!important;background:#FFC60C!important;padding:14px 24px;font-size:16px;">Agree</a>
        </b-modal>
    </div>
</template>
<style scoped>
.setionTitle
{
    font-size:18px;
}
.desc
{
    font-size: 12px;
}
.policyHead
{
    font-size: 16px;
}
</style>